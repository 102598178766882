<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <img src="@/assets/p_23.jpg" />
      <div class="p20">
        <div class="boxText">
          <p>国家一级演奏员、著名二胡演奏家、作曲家，中国二胡学会副会长、江苏省文联副主席、江苏省音协主席、江苏省演艺集团艺术指导委员会主任，中国音乐学院等多所院校客座教授。1991年被国务院授予“政府特殊津贴”，2015年获紫金文化奖章。曾多次赴欧美、东南亚的国家及台湾、香港和澳门等地区访问演出，获得了国内外观众的高度称赞，被誉为“如杯中醇酒，满而不溢”、“弓弓诉人意，弦弦道世情”、“世界一流的弦乐演奏家”、“人间国宝”。</p>
          <p>2013年彭家鹏远赴以培养世界大师级指挥而闻名的维也纳音乐表演艺术大学深造欧洲歌剧，获维也纳音乐表演艺术大学歌剧指挥毕业证书，得到导师康阿德•莱茵特纳教授评语：“彭家鹏先生有着非常不凡的才华、高度的敏锐和细腻的乐感，这使他的指挥艺术臻于完美”。</p>
          <img src="@/assets/p_24.jpg" />
          <p>自2000年在奥地利维也纳金色大厅成功指挥“中国—维也纳新春交响音乐会”后，他连续13年在维也纳金色大厅指挥世界相关乐团，引起巨大轰动。他先后率团赴瑞士、德国、美国举行中国民族</p>

          <p>交响音乐会，应邀指挥日本、朝鲜、韩国、新加坡、香港、澳门、捷克、乌克兰及奥地利的著名乐团。奥地利《信报》称他为“兼有日本小泽征尔和意大利指挥大师穆蒂的指挥风范”，当代奥地利最具有权威的音乐评论家辛科·卫奇评价他“生机盎然、热情洋溢、手部动作快而线条优美，具有独特的风格”。</p>

          <p>他积极付诸于中国民族歌剧和民族交响音乐的探索与实践，曾在第一届中国歌剧节担任歌剧《原野》指挥，第二届歌剧节担任意大利歌剧“茶花女”指挥并获奖。</p>


        </div>
        
      </div>
      <Footer />

    </div>
    
  </div>
</template>

<style scoped>
 
</style>
<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isIndex: false,
      joinList:[
        {
          title:"招聘岗位名称1",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体。乐团由曾经十三次赴维也纳金色大厅指挥的著名指挥家彭家鹏任艺术总监兼首席指挥，由著名二胡演奏家、作曲家朱昌耀任艺术指导。聘请中国音乐家协会名誉主席、著名作曲家赵季平，中国民族管弦乐学会会长、著名作曲家刘锡津，中国民族管弦乐学会副会长兼秘书长王书伟，中国民族管弦乐学会荣誉会长、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:true
        },{
          title:"招聘岗位名称2",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        },{
          title:"招聘岗位名称3",
          othe:"时间/地点",
          describe:"乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        }
      ]
    }
  },
  computed: {
      
  },
  mounted() {
    
  },
  methods:{
    joinView(index){
      this.joinList[index].show = !this.joinList[index].show
    }
    // getCompanys(){
      
    // }
    
  },
}
</script>

